import React, {useState, useEffect} from 'react'
import "../../styles/components/test-components.scss";
import { BiCheck } from "react-icons/bi"; 
import Axios from "axios"
import { Oval } from  'react-loader-spinner'
import { Link } from "react-router-dom"


const TestFinished = (props) => {

  const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1";
  //URL where we will post
  const url = `${apiUrl}/api/test/${props.testTitle}/`

  //State to check if we are loading the data
  const [isLoading, setIsLoading] = useState(true)
  //State to store the ID of the test from the server response
  const [answerId, setAnswerId] = useState("")

  //Effect to make the POST request with Axios only the first time we render the component. It also cleans up the localstorage. 
  useEffect(() => {
    let isMounted = true;    

    Axios.post(url, props.testAnswer)
      .then((res) => {
        if (isMounted) {
          setAnswerId(res.data.id);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        if (isMounted) {
          setIsLoading(false);
          // Handle error, e.g., show an error message to the user
        }
      })
      .finally(() => {
        if (isMounted) {
          // Cleanup, remove from localStorage regardless of success or error
          localStorage.removeItem('testAnswer');
        }
      });
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [url, props.testAnswer]);
  
  
  return (
    <> 
  <div className="tip--container beige-bg">
    <div className="tip--content-container">
      <div className="tip--title-and-progress-container">
        <h3 className="tip--question-label black-font">You finished the test!</h3>
      </div>
          {isLoading ? 
          <button className="button tip--back-button green-button">
            <Oval
            height={38}
            width={38}
            color="#ffffff"
            wrapperStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#ffffff"
            strokeWidth={4}
            strokeWidthSecondary={4}
            />
          </button>: 
        <Link to={`/tests/answer/${answerId}`} className="button tip--back-button green-button">
           <BiCheck className="tip--back-arrow"/> 
        </Link>}
    </div>
  </div>
</>
  );
};

export default TestFinished;
