import React from 'react'
import Navbar from "../components/navbar/Navbar"
import "../styles/components/static-pages.scss"
import hands from "../static/images/hands.jpg"
import { Link } from "react-router-dom"

function Home() {
  return (
    <>
      <Navbar />
      <div className="sp--container beige-bg">
        <div className="mobile-hidden"></div>
        <div className="sp--content-container">
          <img
            src={hands}
            className="sp--hero-img"
            alt="2 hands touch each other"
          />
          <div className="sp--cta-container">
            <h1 className="sp--hero-title green-font">We are here for you</h1>
            <h3 className="sp--hero-subtitle dark-grey-font">
              Start your journey and{" "}
              <span className="sp--accent-words">improve your mental health</span>
            </h3>
            <div className="sp--button-container">
              <Link to="/tests" className="button cta-button orange-button">
                Our tests
              </Link>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}

export default Home