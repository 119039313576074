import React, { useState, useEffect } from "react";
import "../../styles/components/test-components.scss";
import { BiLeftArrowAlt } from "react-icons/bi";

const TestInProgress = (props) => {
  //Copy the questions of the test and add a condition to the questions that are not obligatory
  const initialQuizQuestions = JSON.parse(JSON.stringify(props.testData.questions))
  const [quizQuestions, setQuizQuestions] = useState(props.testData.questions)
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const setTestAnswer = props.setTestAnswer
  const testAnswer = props.testAnswer


  //Function to back the question
  const backQuestion = () => {
    let indexToSlice = currentQuestion
    if (indexToSlice >= 0) {
      let slicedQuestions = quizQuestions.slice(0, indexToSlice)
      slicedQuestions.reverse()
      const questionToBackId = slicedQuestions.find((q) => q.is_obligatory).id
      const questionToBackIndex = quizQuestions.findIndex(q => q.id === questionToBackId)
      setCurrentQuestion(questionToBackIndex)
    }
}

  // Function to update the question condition based on the answers given 
  const updateConditions = () => {
    //Make a copy of the quiz questions in the initial state, so we can manipulate it before changing the real state
    let copiedQuizQuestions = initialQuizQuestions;      
    //Loop through each question that the user has already answered
    testAnswer.user_answers.forEach((a) => {
      const questionId = a.question
      const question = quizQuestions.find((q) => q.id === questionId)
      const chosenAnswer = a.chosen_answer
      const answer = question.answer_set.answers.find((a) => a.id === chosenAnswer)
      
      //If the question has children and the value meets the criteria, loop through children questions
      if (question.positive_child_questions && answer.value > 1){
        question.positive_child_questions.forEach((cq =>{
          //For each child question, find its index and the content of the question
          const childQuestionId = cq.id
          const childQuestion = quizQuestions.find((q) => q.id === childQuestionId)
          const childQuestionIndex = quizQuestions.findIndex(q => q.id === childQuestionId)
          //If we found the question, change it in the same index adding an "is_obligatory" property:
          if (childQuestionIndex !== -1) {
            copiedQuizQuestions[childQuestionIndex] = {...childQuestion, is_obligatory: true}
          }     
        }))  
      } else if (question.negative_child_questions && answer.value < 3) {
        question.negative_child_questions.forEach((cq =>{
          //For each child question, find its index and the content of the question
          const childQuestionId = cq.id
          const childQuestion = quizQuestions.find((q) => q.id === childQuestionId)
          const childQuestionIndex = quizQuestions.findIndex(q => q.id === childQuestionId)
          //If we found the question, change it in the same index adding an "is_obligatory" property:
          if (childQuestionIndex !== -1) {
            copiedQuizQuestions[childQuestionIndex] = {...childQuestion, is_obligatory: true}
          }     
        })) 
      }

      //Clean the answers until there are not obligatory questions in the user answers
      const copiedQuestion = copiedQuizQuestions.find((q) => q.id === questionId)
      if (copiedQuestion.is_obligatory === false){
        let newTestAnswer = {...testAnswer, user_answers: [...testAnswer.user_answers].filter(q => q.question !== copiedQuestion.id)}
        setTestAnswer(newTestAnswer)
      }

      //After checking each question for positive and negative children, and deleting the answers that refer to non-obligatory questions, update the real quiz questions with the updated conditions.
      setQuizQuestions(copiedQuizQuestions)
      //Find the next question that the user will answer
      const nextQuestionIndex = quizQuestions
      .slice(currentQuestion + 1)
      .findIndex((q) => q.is_obligatory);
      if (nextQuestionIndex === -1) {
        props.finishTest();
      } else {
        // Adjust the calculation to find the accurate index of the next obligatory question
        const adjustedNextQuestionIndex =
          currentQuestion + 1 + nextQuestionIndex;
        setCurrentQuestion(adjustedNextQuestionIndex);
      }
    })
  }
 
  //Call the function to update the conditions
  useEffect(updateConditions, [testAnswer])
  
  // New effect to calculate the initial question
  useEffect(() => {
    const unansweredQuestionIndex = quizQuestions.findIndex(
      (q, index) => q.is_obligatory && !testAnswer.user_answers.some((a) => a.question === q.id)
    );
    if (unansweredQuestionIndex !== -1) {
      setCurrentQuestion(unansweredQuestionIndex);
    } else {
      // If there are no unanswered obligatory questions, finish the test
      props.finishTest();
    }
  }, []); // Empty dependency array ensures this effect runs only once during component mount

  // Function to answer a question and make changes to the test if the question has children
  const handleAnswer = (questionId, chosenAnswer) => {    
    const copiedUserAnswers = [...testAnswer.user_answers]
    let filteredCopiedUserAnswers = copiedUserAnswers.filter(
      (answer) => answer.question !== questionId
    );

    let newTestAnswer = {...testAnswer, user_answers: [
        ...filteredCopiedUserAnswers,
        { question: questionId, chosen_answer: chosenAnswer },
      ]
    }
    //Clean the questions that are not obligatory
    newTestAnswer.user_answers.forEach(a => {
      const answerIndex = quizQuestions.findIndex(q => q.id === a.question)
      if (quizQuestions[answerIndex] !== undefined && quizQuestions[answerIndex].is_obligatory === false){
          const indexToRemove = newTestAnswer.user_answers.findIndex(answer => answer.id === a.question)
          newTestAnswer.user_answers.splice(indexToRemove,1);
      } 
    })
    setTestAnswer(newTestAnswer);
  };


  //The class for each answer, so when the user backs up it shows the selected answer.
  const classForSelectedAnswer = (questionId, answerId, answerSetPK) => {
    if (answerSetPK === 1) {
      const filteredAnswer = testAnswer.user_answers.filter(answer => answer.question === questionId)
      if (filteredAnswer.length === 0) {return "tip--gaussian-question-answer dark-grey-font"}
      if (filteredAnswer[0].chosen_answer === answerId) {
        return "tip--gaussian-question-answer dark-grey-font tip--selected-question-answer"
      }  else {return "tip--gaussian-question-answer dark-grey-font"}
    } else {
      const filteredAnswer = testAnswer.user_answers.filter(answer => answer.question === questionId)
      if (filteredAnswer.length === 0) {return "tip--stair-question-answer dark-grey-font"}
      if (filteredAnswer[0].chosen_answer === answerId) {
        return "tip--stair-question-answer dark-grey-font tip--selected-question-answer"
      }  else {return "tip--stair-question-answer dark-grey-font"}
    }
  }
  // WHAT TYPE OF DATA THE SERVER EXPECTS ON POST REQUEST
  // {
  //   quiz: 1,
  //   userAnswers: [
  //     { question: 5, chosen_answer: 8 },
  //     { question: 10, chosen_answer: 10 },
  //     { question: 3, chosen_answer: 12 },
  //   ],
  // };  

  return (
  <> 
  <div className="tip--container beige-bg">
    <div className="tip--content-container">
      <div className="tip--title-and-progress-container">
        <h2 className="tip--quiz-progress dark-grey-font">{currentQuestion+1} / {quizQuestions.length}</h2>
      <h3 className="tip--question-label black-font">
        {quizQuestions[currentQuestion].label}
      </h3>
      </div>
      <div className="tip--question-answer-container">
        {quizQuestions[currentQuestion].answer_set.answers.map((a) => (
          <p
            key={a.id}
            className={classForSelectedAnswer(quizQuestions[currentQuestion].id, a.id, quizQuestions[currentQuestion].answer_set.pk)}
            onClick={() => handleAnswer(quizQuestions[currentQuestion].id, a.id)}
          >
            {a.text}
          </p>
        ))}
      </div>
      {currentQuestion > 0 ? 
        <button
          className="button tip--back-button green-button"
          onClick={() => backQuestion()}
        >
          
            <BiLeftArrowAlt className="tip--back-arrow"/>
          
        </button>: <div></div>
      }
    </div>
  </div>
</>
  );
};

export default TestInProgress;
