import React, {useEffect, useState} from 'react'
import Navbar from "../components/navbar/Navbar"
import Card from "../components/test-card/Card"
import "../styles/components/static-pages.scss"
import tests from "../static/images/tests.jpg"
import { BiDownArrowAlt } from "react-icons/bi"
import Axios from "axios"
// import { Link } from "react-router-dom"

function Tests() {
const [testData, setTestData] = useState([])
  
useEffect(() => {
  const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1";
  Axios.get(`${apiUrl}/api/all-tests/`).then((res) => {
    setTestData(res.data);
  });
}, []);
  

const testCards = testData.map(i => {
  return (
  <Card
    key={i.title}
    title={i.title}
    description={i.description}
    questions={i.number_of_questions}
    time={i.time_to_complete}
    />
    )
}
)



  return (
    <>
      <Navbar />
      <div className="sp--container beige-bg">
        <div></div>
        <div className="sp--content-container">
          <img
            src={tests}
            className="sp--hero-img"
            alt="One kid with hands painted with many colors"
          />
          <div className="sp--cta-container">
            <h1 className="sp--hero-title green-font">Discover yourself</h1>
            <h3 className="sp--hero-subtitle dark-grey-font">
              Free. No email required. Easy and detailed answers.
            </h3>
            <div className="sp--button-container">
              <a
                href="#all-tests"
                className="button cta-button-single orange-button"
              >
                See the tests
              </a>
            </div>
          </div>
        </div>
        <a href="#all-tests" className="sp--arrow black-font">
          <BiDownArrowAlt />
        </a>
      </div>
      <div className="sp--container light-green-bg">
        <div className="sp--simple-content-container">
          <h2 id="all-tests" className="sp--section-title black-font">Our tests</h2>
          <div className="sp--test-container">
            {testCards}
          </div>
        </div>
      </div>
    </>
  );
}

export default Tests