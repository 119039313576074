import React from 'react'
import { NavLink } from "react-router-dom";

// Simple link menu in dropdown that sends to another page
const DropdownItem = (props) => {

  const handleClick = () => {
    props.toggleMenu()
    props.setOpen(false)
  } 

  return (
    <NavLink
        end
        className={({ isActive }) =>
          isActive ? "dropdown-active-link dropdown-link" : "dropdown-link"
        }
        to={props.link}
        onClick={handleClick}
      >
        {props.leftIcon && <span className="icon-button">{props.leftIcon}</span>}
        {props.text && <div className={props.rightIcon ? "dropdown-link-text" : "dropdown-link-text flex-centered"}>{props.text}</div>}
        {props.rightIcon && <span className="right-icon">{props.rightIcon}</span>}
      </NavLink>
  )
}

export default DropdownItem