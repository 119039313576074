import React from "react";
import "../../styles/components/results-card.scss";
import placeholder from "../../static/images/image-placeholder.jpg";

function RecommendationCard(props) {
  var typeClassName = "";

  if (props.type === "Book") {
    typeClassName = "beige-font orange-bg rec--type";
  } else if (props.type === "Course") {
    typeClassName = "beige-font green-bg rec--type";
  } else if (props.type === "Object") {
    typeClassName = "beige-font orange-bg rec--type";
  } else if (props.type === "Guided Experience") {
    typeClassName = "beige-font green-bg rec--type";
  }

  const handleImageError = (event) => {
    // Handle image loading error by replacing it with a placeholder
    event.target.src = placeholder;
  };

  return (
    <div className="recommendation-card dark-grey-font">
      <div className="rec--image-container">
        <p className={typeClassName}>{props.type}</p>
        <a href={props.url}>
          {props.image ? (
            <img
              className="rec--img"
              onError={handleImageError}
              src={process.env.PUBLIC_URL + `/recommendations${props.image}`}
              alt=""
            />
          ) : (
            <img
              className="rec--img"
              src={placeholder}
              onError={handleImageError}
              alt=""
            />
          )}
        </a>
      </div>

      <div className="rec--text-container">
        <h3 className="rec--title black-font">{props.title}</h3>
        {props.author && (
          <p className="dark-grey-font lighter-tone">by {props.author}</p>
        )}
        <p className="rec--description dark-grey-font">{props.description}</p>
        <div className="rec--pros-cons-container">
          <>
            <div className="rec--pros-container">
              <p className="rec--pros-title">PROS</p>
              <p className="rec--pros">{props.pros}</p>
            </div>
            <div className="rec--cons-container">
              <p className="rec--cons-title">CONS</p>
              <p className="rec--cons">{props.cons}</p>
            </div>
          </>
        </div>
        <a className="rec--link" href={props.url} target="_blank" rel="noopener noreferrer" >
          CHECK IT OUT
        </a>
      </div>
    </div>
  );
}

export default RecommendationCard;