import React, {useState, useEffect, useRef} from "react";


function NavbarItemWithDropdown(props) {
  const [open, setOpen] = useState(false)
  const node = useRef();
  //Mounts the mouse handler check
  //If a click outside the dropdown occurs, it closes the dropdown.
  useEffect(() => {
      //add when mounted
      document.addEventListener("mousedown", handleClick);
      return () => {
          document.removeEventListener("mousedown", handleClick);
      };
  }, []);

  const handleClick = (e) => {
      if (node.current.contains(e.target)) {
          //inside element click, returns no change
          return;
      }
      //if clicked outside, forces the drop down to close.
      setOpen(false);
  }; 

  // add setOpen prop to all children
  const updateChildrenWithProps = React.Children.map(
    props.children,
    (child, i) => {
      return React.cloneElement(child, { setOpen: setOpen });
    }
  );

  return (
    <li ref={node}>
      <button
        className={"navbar-link"}
        onClick={() => setOpen(!open)}
      >
        {props.text && <div className={props.rightIconMobile ? "navbar-link-text" : "navbar-link-text flex-centered"}>{props.text}</div>}
        {props.rightIconDesktop && <span className="navbar-right-icon mobile-hidden">{props.rightIconDesktop}</span>}
        {props.rightIconMobile && <span className="navbar-right-icon desktop-hidden">{props.rightIconMobile}</span>}
      </button>
      {open && updateChildrenWithProps}
    </li>
  );
}

export default NavbarItemWithDropdown;
