import React from 'react';
import "../../styles/components/static-pages.scss";
import { BiQuestionMark, BiTime} from "react-icons/bi"

const TestInstructions = (props) => {
    const testData = props.testData 
    
    return (
      <>
        <div className="sp--container beige-bg">
          <div className="desktop-hidden"></div>
          <div className="sp--content-container sp--top-margin">
            <img
              src={require(`../../static/test_images/${testData.title}.jpg`)}
              className="sp--hero-img"
              alt=""
            />
            <div className="sp--cta-container">
              <h1 className="sp--test-title green-font">{testData.title}</h1>
              <div className="sp--test-details-container dark-grey-font">
                <p className="sp--test-detail">
                  <span className="sp--icon">
                    <BiQuestionMark />
                  </span>
                  {testData.number_of_questions} questions
                </p>
                <p className="sp--test-detail">
                  <span className="sp--icon">
                    <BiTime />
                  </span>
                  {testData.time_to_complete} minutes
                </p>
              </div>
              <p className="sp--test-subtitle dark-grey-font">
                {testData.description}
              </p>
              <h2 className="sp--second-title green-font">Instructions</h2>
              <p className="sp--test-subtitle dark-grey-font">
                {testData.instructions}
              </p>
              <div className="sp--button-container">
                <button onClick={props.startTest} className="button cta-button-single orange-button">
                  Start
                </button>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </>
    );
  };

export default TestInstructions;