import React, {useState} from "react";
import { BiChevronRight, BiArrowBack } from "react-icons/bi";
import { CSSTransition } from "react-transition-group";
import DropdownItem from "./DropdownItem"

const DropdownMenu = (props) => {

  const [activeMenu, setActiveMenu] = useState("main")
  const [menuHeight, setMenuHeight] = useState(null);

  function calcHeight(el) {
    const height = el.offsetHeight + 30;
    setMenuHeight(height);
  }

  const DropdownMenuItem = (props) => {
    return (
      <div
          className={props.backingButton ? "dropdown-link desktop-hidden" : "dropdown-link"}
          onClick={props.backingButton ? () => props.setOpen(false) : () => props.goToMenu && props.setActiveMenu(props.goToMenu)}
        >
          {props.leftIcon && <span className="icon-button">{props.leftIcon}</span>}
          {props.text && <div className={props.rightIcon ? "dropdown-link-text" : "dropdown-link-text flex-centered"}>{props.text}</div>}
          {props.rightIcon && <span className="right-icon">{props.rightIcon}</span>}
        </div>
    )
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }}>
      
      {/* MAIN MENU */}
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
        <DropdownMenuItem
            backingButton={true}
            setOpen = {props.setOpen}
            leftIcon={<BiArrowBack />}
          />
          <DropdownItem
            toggleMenu={props.toggleMenu}
            setOpen = {props.setOpen}
            text="Our tests"
            link="/tests"
          />
          <DropdownMenuItem
            text="Take a test"
            goToMenu="takeTest"
            rightIcon={<BiChevronRight />}
            setActiveMenu={setActiveMenu}
          />
        </div> 
      </CSSTransition>
      
      {/* SECONDARY MENU */}
      <CSSTransition
        in={activeMenu === "takeTest"}
        unmountOnExit
        timeout={250}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
        <DropdownMenuItem
            goToMenu="main"
            leftIcon={<BiArrowBack />}
            setActiveMenu={setActiveMenu}
          />
          
          <DropdownItem
            setOpen = {props.setOpen}
            toggleMenu={props.toggleMenu}
            text="Present"
            link="/tests/Present"
          />
          <DropdownItem
            setOpen = {props.setOpen}
            toggleMenu={props.toggleMenu}
            text="Past"
            link="/tests/Past"
          />
          <DropdownItem
            setOpen = {props.setOpen}
            toggleMenu={props.toggleMenu}
            text="Personality"
            link="/tests/Personality"
          />
        </div>
      </CSSTransition>
    </div>
  );
};

export default DropdownMenu;
