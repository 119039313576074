import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar/Navbar';
import { Dna } from 'react-loader-spinner';
import TestInstructions from '../components/test/TestInstructions';
import TestInProgress from '../components/test/TestInProgress';
import TestFinished from '../components/test/TestFinished';
import { useParams } from 'react-router-dom';
import '../styles/components/static-pages.scss';
import Axios from 'axios';

function TestDetail() {
  const { testTitle } = useParams();

    // State to keep the quiz data
  const [thisTest, setThisTest] = useState([]);
  // What part of the test are we in
  const [testStatus, setTestStatus] = useState('loading');
  // State to keep track of user answers, we will send the data with an API call when they are ready
  const [testAnswer, setTestAnswer] = useState([]);

  useEffect(() => {
    setTestStatus('loading');
    const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1";
    Axios.get(`${apiUrl}/api/test/${testTitle}/`).then((res) => {
      const quiz = res.data['quiz'][0];
      setThisTest(quiz);
  
      // Get localStorage
      const storedTestAnswer = localStorage.getItem('testAnswer');
  
      if (storedTestAnswer !== null) {
        const parsedTestAnswer = JSON.parse(storedTestAnswer);
        if (parsedTestAnswer.quiz === quiz.id) {
          setTestAnswer(parsedTestAnswer);
        } else {
          localStorage.removeItem('testAnswer');
          setTestAnswer({ quiz: quiz.id, user_answers: [] });
        }
      } else {
        // Handle the case when there's no data in localStorage
        setTestAnswer({ quiz: quiz.id, user_answers: [] });
      }
    
        setTestStatus('instructions');
      });
    }, [testTitle]);
  

  // Finish test
  const finishTest = () => {
    setTestStatus('finished');
  };

  // Update localStorage whenever testAnswer changes
  useEffect(() => {
    if (testAnswer.length !== 0) {
      localStorage.setItem('testAnswer', JSON.stringify(testAnswer))
    }
  }, [testAnswer]);

  return (
    <>
      <Navbar />
      {testStatus === 'loading' && (
        <div className="tr--loading">
          <Dna
            visible={true}
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="tr--loader"
          />
        </div>
      )}

      {testStatus === 'instructions' && (
        <TestInstructions startTest={() => setTestStatus('inProgress')} testData={thisTest}/>
      )}
      {testStatus === 'inProgress' && (
        <TestInProgress
          testData={thisTest}
          testAnswer={testAnswer}
          setTestAnswer={setTestAnswer}
          finishTest={finishTest}
        />
      )}
      {testStatus === 'finished' && <TestFinished testTitle={testTitle} testAnswer={testAnswer}/>}
    </>
  );
}

export default TestDetail;
