import React, {useState, useEffect} from 'react'
import Navbar from "../components/navbar/Navbar"
import ResultsCard from "../components/results/ResultsCard"
import TraitCard from "../components/results/TraitCard"
import RecommendationCard from "../components/results/RecommendationCard"
import SummaryCard from "../components/results/SummaryCard"
import EmailFormWithPopup from "../components/results/EmailFormWithPopup"  // Import your EmailForm component
import colors from "../styles/abstracts/_index.scss";
import { Dna } from  'react-loader-spinner'
import {useParams} from "react-router-dom"
import "../styles/components/test-results.scss"
import Axios from "axios"


function TestResults() {
  const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1";
  const { testId } = useParams();
  //State to check if we are loading the test or it's already loaded
  const [isLoading, setIsLoading] = useState(true);
  //State to load the data of the results
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(true);  // New state for toggling form and message
  const handleEmailFormSuccess = () => {
    setShowForm(false);  // Set showForm to false when the email is sent successfully
  };



  // Down will be a useEffect API call to find the test
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1";
    Axios.get(`${apiUrl}/api/${testId}/`).then((res) => { 
    setData(res.data)
    setIsLoading(false)
    });
  }, [testId]);

  const primaryColors = [colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown,
    colors.green, colors.orange, colors.purple, colors.blue, colors.brown]
  const secondaryColors = [colors.colora, colors.colorb, colors.colorc, colors.colord, colors.colore, colors.colorf, colors.colorg, colors.colorh,
    colors.colora, colors.colorb, colors.colorc, colors.colord, colors.colore, colors.colorf, colors.colorg, colors.colorh,
    colors.colora, colors.colorb, colors.colorc, colors.colord, colors.colore, colors.colorf, colors.colorg, colors.colorh,
    colors.colora, colors.colorb, colors.colorc, colors.colord, colors.colore, colors.colorf, colors.colorg, colors.colorh,
    colors.colora, colors.colorb, colors.colorc, colors.colord, colors.colore, colors.colorf, colors.colorg, colors.colorh,
    colors.colora, colors.colorb, colors.colorc, colors.colord, colors.colore, colors.colorf, colors.colorg, colors.colorh,
    colors.colora, colors.colorb, colors.colorc, colors.colord, colors.colore, colors.colorf, colors.colorg, colors.colorh]
  
  //Method to filter recommendations using map method  
  const filterRecommendations = (recommendationType) => {
    return data.score[0].recommendations
      .filter(r => recommendationType === r.type)
      .sort((a, b) => a.order - b.order) // Sort by the order attribute
      .map((r, index) => (
        <RecommendationCard
          key={index}
          index={index}
          title={r.title}
          image={r.image}
          description={r.description}
          author={r.author}
          type={r.type}
          pros={r.pros}
          cons={r.cons}
          url={r.url}
        />
      ));
  }
  return (
    <>
      <Navbar />
      <div className="tr--container beige-bg">
      <div className="tr--simple-content-container">

      {isLoading ? <div className="tr--loading">
        <Dna
          visible={true}
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="tr--loader"
        />
        </div>
         : (
          <>
        {/*Index */}
      <div className="tr--index dark-green-bg">
      <h4 className="tr--index-title mobile-hidden">Go to:</h4>
      {data.score[0].achievements.length > 0 && <a href="#traits" className="tr--index-link">Traits</a>}
      {data.score[0].interpretations.length > 0 && <a href="#interpretation" className="tr--index-link">Interpretation</a>}
      {data.score[0].recommendations.length > 0 && <a href="#recommendations" className="tr--index-link">Recommendations</a>}
      <a href="#results" className="tr--index-link">Raw data</a>
      </div>
        
        <div className="tr--main-content">
            
         {/* Email form with popup */}
         <EmailFormWithPopup
          onSuccess={handleEmailFormSuccess}
          frontendUrl={`${apiUrl}/tests/answer/${testId}`}
          formInteracted={!showForm}
        />


           {/*Achievements */}
           {data.score[0].achievements.length > 0 &&
           <>
           <h2 className="tr--title green-font" id="traits">Traits</h2>
          <h3 className="tr--subtitle dark-grey-font">Down below you can see your traits.</h3>
          <div className="tr--rc-container">
          {data.score[0].achievements.map((i, index )=> (
            <TraitCard
            key = {index}
            index = {index}
            title = {i.title}
            subtitle = {i.subtitle}
            icon = {i.icon}
            />
          ))}
          </div>
          </>
          }
         
        {/*Interpretations */}
        {data.score[0].interpretations.length > 0 && <>
          <h2 className="tr--title green-font" id="interpretation">Interpretation of your results</h2>
          <h3 className="tr--subtitle dark-grey-font">Down below you can see your score in different categories.</h3>
          <div className="tr--rc-container">
          {data.score[0].interpretations.map((i, index )=> (
            <ResultsCard
            key = {`${i.title}-${index}`}
            index = {index}
            title = {i.title}
            icon = {i.icon}
            score = {i.score}
            explanation = {i.explanation}
            personalized_explanation = {i.personalized_explanation}
            subcategories = {i.subcategories}
            primaryColors = {primaryColors}
            secondaryColors = {secondaryColors}
            />
          ))}
          </div>
        </>}
          {/*Recommendations */}
          {data.score[0].recommendations.length > 0 && <>
          <h2 className="tr--title green-font" id="recommendations">Recommendations</h2>
          <h4 className="dark-grey-font">Some of these are affiliate links.</h4>

          <h3 className="tr--subtitle dark-grey-font">Down below you can see the resources that we recommend you.</h3>

          {/* Programs */}
          {filterRecommendations("Guided Experience").length > 0 &&
          <>
          <h2 className="tr--rec-title green-font">Guided Experiences</h2>
          <div className="tr--rc-container">
          {filterRecommendations("Guided Experience")}
          </div>
          </>}


          {/* Books */}
          {filterRecommendations("Book").length > 0 &&
          <>
          <h2 className="tr--rec-title orange-font">Books</h2>
          <div className="tr--rc-container">
          {filterRecommendations("Book")}
          </div>
          </>}

          {/* Courses */}
          {filterRecommendations("Course").length > 0 &&
          <>
          <h2 className="tr--rec-title green-font">Courses</h2>
          <div className="tr--rc-container">
          {filterRecommendations("Course")}
          </div>
          </>}

          {/* Objects */}
          {filterRecommendations("Object").length > 0 &&
          <>
          <h2 className="tr--rec-title orange-font">Object</h2>
          <div className="tr--rc-container">
          {filterRecommendations("Object")}
          </div>
          </>}

          {/* Other */}
          {filterRecommendations("Other").length > 0 &&
          <>
          <h2 className="tr--rec-title green-font">Other recommendations</h2>
          <div className="tr--rc-container">
          {filterRecommendations("Other")}
          </div>
          </>}
        </>}

          
          {/*Raw results */}
          <h2 className="tr--title green-font" id="results">Raw results</h2>
          <h3 className="tr--subtitle dark-grey-font">Down below you can see your results without any additional interpretations.</h3>
          <div className="tr--rc-container">
          {/*Summary */}
          <SummaryCard data={data.score[0].score} title="Summary" secondaryColors={secondaryColors}/>
          
          {data.score[0].score.map((i, index )=> (
            <ResultsCard
            key = {`${i.title}-${index}`}
            index = {index}
            title = {i.title}
            icon = {i.icon}
            score = {i.score}
            explanation = {i.explanation}
            personalized_explanation = {i.personalized_explanation}
            subcategories = {i.subcategories}
            primaryColors = {primaryColors}
            secondaryColors={secondaryColors}
            />
          ))}
          </div>

        </div>
        </>
      )}
            </div>
      </div>
    </>
  );
}

export default TestResults