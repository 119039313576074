import React from "react";
import ReactDOM from "react-dom/client";
//Utilities for cookies, routing, helmet for titles, etc
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import CookiesBanner from "./components/cookies-banner/CookiesBanner";
import favicon from "./static/brand/favicon/logo192.png"
//Pages with different routing
import Home from "./pages/Home";
import Tests from "./pages/Tests";
import TestDetail from "./pages/TestDetail";
import TestResults from "./pages/TestResults";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    //<React.StrictMode>
  <>
    <CookiesBanner/>
    <BrowserRouter>
        <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <link rel="icon" type="image/png" href={favicon} />
                <title>Atrapamente - Home</title>
              </Helmet>
              <Home />
            </>
          }
        />
          <Route
          path="/tests"
          element={
            <>
              <Helmet>
                <link rel="icon" type="image/png" href={favicon} />
                <title>Atrapamente - Tests</title>
              </Helmet>
              <Tests />
            </>
          }
        />
          <Route
          path="/tests/:testTitle"
          element={
            <>
              <Helmet>
              <link rel="icon" type="image/png" href={favicon} />
                <title>Atrapamente - Take a test</title>
              </Helmet>
              <TestDetail/>
            </>
          }
        />
          <Route
          path="/tests/answer/:testId"
          element={
            <>
              <Helmet>
                <link rel="icon" type="image/png" href={favicon} />
                <title>Test Results</title>
              </Helmet>
              <TestResults />
            </>
          }
          />

          <Route path="/en/*" element ={<Navigate to="/" replace/>}/>
          <Route path="/es/*" element ={<Navigate to="/" replace/>}/>
        </Routes>
    </BrowserRouter>
  </>
  //</React.StrictMode>
);