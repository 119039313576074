import React from "react";
import "../../styles/components/test-card.scss"
import { BiQuestionMark, BiTime} from "react-icons/bi"
import { Link } from "react-router-dom"


function Card(props) {
  
  return (
    <Link to={`/tests/${props.title}`} className="card beige-font">
      <div className="card-title-container">
        <h3 className="card-title ">{props.title}</h3>
        <div className="card-test-details-container">
          <p className="card-test-detail"><span className="card-icon"><BiQuestionMark/></span>{props.questions}</p>
          <p className="card-test-detail"><span className="card-icon"><BiTime/></span>{props.time}</p>
        </div>
      </div>
      <p className="card-description">{props.description}</p>
      <p className="card-cta">Start</p>
    </Link>
  );
}

export default Card;