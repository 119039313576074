import React, { useState } from 'react';
import Axios from 'axios';

const EmailForm = ({onSuccess, frontendUrl, formInteracted}) => {
  const [email, setEmail] = useState('');
  const [subscribe, setSubscribe] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCheckboxChange = () => {
    setSubscribe(!subscribe);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!isSubmitting) {
      setIsSubmitting(true);
      const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1";
      try {
        const response = await Axios.post(
          `${apiUrl}/api/send-email/`,
          { email, frontendUrl, subscribe },
        );
        console.log('Email sent successfully:', response.data);
        onSuccess(); // Notify the parent component about the success
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Error sending email. You can copy the URL to keep your results while we fix this.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="tr--email-form">
      {!formInteracted ? (
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="email">Do you want an email with your results?</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button type="submit" disabled={isSubmitting}>
            Send
          </button>
          <div className="tr--email-form-checkbox">
            <label htmlFor="subscribeCheckbox">
              <input
                type="checkbox"
                id="subscribeCheckbox"
                checked={subscribe}
                onChange={handleCheckboxChange}
              />
              Send me tips about self-improvement and mental health
            </label>
          </div>
        </form>
      ) : (
        <p className="tr--email-form-success-message">
          Email sent! Check the spam folder
        </p>
      )}
    </div>
  );
};

export default EmailForm;

