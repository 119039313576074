import React, { useState, useEffect } from 'react';
import EmailForm from './EmailForm';

const EmailFormWithPopup = ({ onSuccess, frontendUrl, formInteracted }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (!formInteracted) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
      }, 250000); // 3 minutes in milliseconds

      return () => clearTimeout(timeout);
    }
  }, [formInteracted]);

  return (
    <>
      {showPopup && (
        <div className="tr--modal-overlay" onClick={handlePopupClose}>
          <div className="tr--email-form-modal-container">
            <div
              className="tr--email-form-modal"
              onClick={(e) => e.stopPropagation()}
            >
              <EmailForm onSuccess={onSuccess} frontendUrl={frontendUrl} formInteracted={formInteracted}/>
            </div>
          </div>
        </div>
      )}

      {!showPopup && (
          <EmailForm onSuccess={onSuccess} frontendUrl={frontendUrl} formInteracted={formInteracted}/>
      )}
    </>
  );
};

export default EmailFormWithPopup;




