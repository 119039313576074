import React from "react"
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';

function CookiesBanner() {
  return (
  <CookieConsent
    enableDeclineButton
    debug={false}
    buttonText="I accept"
    declineButtonText="No thanks"
    location="bottom"
    cookieName="CookieConsent"
    onAccept={() => {
      ReactGA.initialize('G-7GT8M0GY3L');
      ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    }}
    onDecline={() => {
    }}
    
    style={{
      background: "rgb(50, 133, 117)",
      fontSize: "24px",
      textAlign: "center",
      alignItems:"center",
      justifyContent:"center",
      position: "absolute",
      padding: "20px",
      maxHeight: "600px", // Adjust this value as needed
    }}
    contentStyle={{
      maxWidth: "800px",
    }}
    buttonStyle={{
      background: "rgb(224, 110, 61)",
      color: "rgb(248, 244, 242)",
      fontSize: "21px",
      borderRadius: "4px",
      padding: "15px 30px",
      margin: "10px",
    }}
    declineButtonStyle={{
      background: "rgb(19, 99, 89)",
      color: "rgb(248, 244, 242)",
      fontSize: "12px",
      borderRadius: "4px",
      padding: "15px 30px",
      margin: "10px",
    }}
    expires={365}
    overlay={true}
  >
    This website uses cookies to enhance the user experience.{" "}
  </CookieConsent>
  )}

export default CookiesBanner