import React from "react";
import "../../styles/components/results-card.scss"
import {Chart as ChartJS, CategoryScale, LinearScale,
  BarElement, Title, Tooltip, Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ResultsCard(props) {
  
  function RGBtoRGBA(colorString) {
    // Check if the input is in the format #RRGGBB or rgb(r, g, b)
    if (/^#([0-9a-fA-F]{6})$/.test(colorString)) {
        // If it's in the #RRGGBB format, convert to RGB
        const hex = colorString.slice(1);
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        // Return the RGBA color with alpha 0.65
        return `rgba(${r}, ${g}, ${b}, 0.65)`;
    } else if (/^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/.test(colorString)) {
        // If it's in the rgb(r, g, b) format, extract the RGB values
        const match = colorString.match(/^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/);
        const r = match[1];
        const g = match[2];
        const b = match[3];
        // Return the RGBA color with alpha 0.65
        return `rgba(${r}, ${g}, ${b}, 0.65)`;
    } else {
        // Invalid input format
        console.error("Invalid color format. Please provide a valid RGB or hex color.");
        return null;
    }
}

  //Colors, primary to the titles and main categories, secondary to the subcategories
  const primaryColors = props.primaryColors
  const secondaryColors = props.secondaryColors
  //Function to prepare the labels and score, it's a conditional block based on the subcategories
  //Empty lists that we will fill
  var labels = []
  var dataset = []
  var borderColor = [primaryColors[props.index]]
  var backgroundColor = [RGBtoRGBA(primaryColors[props.index])]

  //Case 1: no subcategories
  if (props.subcategories) {
    labels.push("Average")
    dataset.push(props.score)
    props.subcategories.forEach((i, index) => {
      labels.push(i.title)
      dataset.push(i.score)
      borderColor.push(secondaryColors[index])
      backgroundColor.push(RGBtoRGBA(secondaryColors[index]))
    }) 
  //Case 2: subcategories  
  } else {
    labels.push("Score")
    dataset.push(props.score)
  }
  //Graph height based on the number of bars to display
  const graphHeight = `${2.75 + 2.75*dataset.length}em`

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio:false,
    scales: {
      y: {
        ticks: {
            font: {size:15}
        }
      },
      x: {
          min:0.0,
          max:10,
          ticks: {
              beginAtZero: true,
              font:{size:15}
          }
      }
  },
    plugins: {
      legend: {
        position: 'bottom',
        display: false
      },
      title: {
        display: false,
        text: 'Im hidden',
      },
    },
  };
  
  const data = {
    labels,
    datasets: [
      {
        label: "Score",
        data: dataset,
        barThickness: 'flex',
        borderColor: borderColor,
        backgroundColor: backgroundColor
      },
    ],
  };

  const primaryColorStyle = {color:primaryColors[props.index], filter: "brightness(0.8)"}
  const secondaryColorStyle = (index) => ({color:secondaryColors[index], filter: "brightness(0.8)"})
  //Explanations personalized based on the presence of subcategories
  var explanation = <></>
  if (props.subcategories) {
    explanation = <>
      <p className="rc--explanation" style={primaryColorStyle}>{props.explanation}</p>
      <p className="rc--personalized-explanation"><span className="rc--score bold" style={primaryColorStyle}>{props.score} out of 10:</span> {props.personalized_explanation}</p>
      {props.subcategories.map((s, index) => (
        <React.Fragment key={s.title}>
        <p className="rc--explanation" style={secondaryColorStyle(index)}>{s.explanation}</p>
        <p className="rc--personalized-explanation"><span style={secondaryColorStyle(index)} className="rc--score bold">{s.score} out of 10:</span> {s.personalized_explanation}</p>
        </React.Fragment>
      ))
      }
    </>
  } else {
    explanation = <React.Fragment>
      <p className="rc--explanation" style={primaryColorStyle}>{props.explanation}</p>
      <p className="rc--personalized-explanation"><span className="rc--score bold" style={primaryColorStyle}>{props.score} out of 10:</span> {props.personalized_explanation}</p>
    </React.Fragment>
  }

  return (
    <div className="results-card dark-grey-font">
      <div className="rc--title-container">
      {props.icon && <div className="rc--icon-container"><img src={require('backend' + props.icon)} alt=""/></div>}
        <h3 className="rc--title" style={primaryColorStyle}>{props.title}</h3>
      </div>
      <div className="rc--graph" style={{height:graphHeight}}>
        <Bar options={options} data={data} />
      </div>
      {explanation}
    </div>
  );
}

export default ResultsCard;