import React from "react";
import "../../styles/components/results-card.scss"



function TraitCard(props) {
  
  return (
    <div className="trait-card dark-grey-font">
      {props.icon && 
      <div className="tc--icon-container">
         <img src={require('backend' + props.icon)} alt=""/>
      </div>
      }
      <div className="tc--text-container">
        <h3 className="tc--title black-font">{props.title}</h3>
        <p className="tc--subtitle dark-grey-font">{props.subtitle}</p>
      </div>
    </div>
  );
}

export default TraitCard;