import React, { useState, useEffect } from "react"
import "../../styles/components/navbar.scss";
import { Link } from "react-router-dom"
import { BiMenu, BiX, BiChevronDown, BiChevronRight } from "react-icons/bi"
import letters from "../../static/brand/logo/letters.png"
import logo from "../../static/brand/logo/logo.png"
// import NavbarItem from "./NavbarItem"; USE FOR SIMPLE LINKS
import NavbarItemWithDropdown from "./NavbarItemWithDropdown";
import DropdownMenu from "./DropdownMenu"


function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false)
    const openMenu = () => setMenuOpen(true)
    const closeMenu = () => setMenuOpen(false)


    const [size, setSize] = useState({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    useEffect(() => {
        if (size.width > 992 && menuOpen){
            setMenuOpen(false)
        }
    }, [size.width, menuOpen])

    return (
      <>
        <nav>
          <div className="navbar-container">
            <Link to="/" className="logo" onClick={closeMenu}>
              <div className="logo-container">
                <img src={logo} alt="" className="logo"/>
                <img src={letters} alt="" className={menuOpen ? "hidden" : "logo-letters"}/>
              </div>
            </Link>

            <div className={`navbar-menu ${menuOpen && "menu-open"}`}>
              <ul>
                <NavbarItemWithDropdown text="Tests" rightIconDesktop={<BiChevronDown />} rightIconMobile={<BiChevronRight />}>
                  <DropdownMenu toggleMenu={closeMenu}/>
                </NavbarItemWithDropdown>
                {/*<NavbarItemWithDropdown text="Online Therapy" rightIconDesktop={<BiChevronDown />} rightIconMobile={<BiChevronRight />}>
                  <DropdownMenu toggleMenu={closeMenu}/>
                </NavbarItemWithDropdown>
                 <NavbarItem text="Therapy Retreat" link="/therapy-retreat" toggleMenu={closeMenu} /> THIS IS HOW TO MAKE A SIMPLE LINK*/}
              </ul>

              {/*<Link
                onClick={closeMenu}
                to="/"
                className="button button-navbar"
              >
                Log in
              </Link>*/}
            </div>

            <div className="toggle-menu">
              {menuOpen ? (
                <BiX onClick={closeMenu} />
              ) : (
                <BiMenu onClick={openMenu} />
              )}
            </div>
          </div>
        </nav>
      </>
    );
}

export default Navbar